import React from "react";
import ReactDOM from "react-dom/client";
import "./css/App.css";
import "./fonts/san-fransisco-font.css";
import LoginApp from "./LoginApp";
import reportWebVitals from "./reportWebVitals";
import "./translations/i18n";
import "./css/index.css";
import messages_ar from "./translations/ar.json";
import messages_da from "./translations/da.json";
import messages_dk from "./translations/dk.json";
import messages_de from "./translations/de.json";
import messages_en from "./translations/en.json";
import messages_es from "./translations/es.json";
import messages_fi from "./translations/fi.json";
import messages_fr from "./translations/fr.json";
import messages_hu from "./translations/hu.json";
import messages_it from "./translations/it.json";
import messages_nb from "./translations/nb.json";
import messages_no from "./translations/no.json";
import messages_nl from "./translations/nl.json";
import messages_ru from "./translations/ru.json";
import messages_sv from "./translations/sv.json";
import messages_tr from "./translations/tr.json";
import messages_zh from "./translations/zh.json";
import LogRocket from "logrocket"; // Import LogRocket here

export const messages = {
  ar: messages_ar,
  da: messages_da,
  de: messages_de,
  dk: messages_dk,
  en: messages_en,
  es: messages_es,
  fi: messages_fi,
  fr: messages_fr,
  hu: messages_hu,
  it: messages_it,
  nl: messages_nl,
  nb: messages_nb,
  no: messages_no,
  ru: messages_ru,
  sv: messages_sv,
  tr: messages_tr,
  zh: messages_zh,
};
export const DEFAULT_LANGUAGE = "Swedish";
export const DEFAULT_LOCALE = "sv-SE";
export const LOCALE_LOCALSTORAGE_KEY = "pifa-language";
const domainArray = [
  "engros.ae",
  "engros.app",
  "engros.at",
  "engros.dev",
  "engros.es",
  "engros.fi",
  "engros.in",
  "engros.io",
  "engros.it",
  "engros.nz",
  "engros.pt",
  "engros.se",
  "engros.uk",
  "partihandel.io",
  "partihandel.se",
  "partihanteraren.com",
];

if (
  domainArray.includes(window.location.host) &&
  localStorage.getItem("_userId") !== undefined
) {
  if (localStorage.getItem("_userId") !== "63867d1c82078539284d7fc3") {
    LogRocket.init("xz56up/pifa");
    LogRocket.identify(localStorage.getItem("uid"), {
      name:
        localStorage.getItem("firstName") +
        " " +
        localStorage.getItem("lastName"),
      email: localStorage.getItem("email"),
      cid: localStorage.getItem("cid"),
      companyName: localStorage.getItem("companyName"),
    });
  }
}
window.addEventListener("error", function (event) {
  console.error("Uncaught error:", event.error);
});

window.addEventListener("unhandledrejection", function (event) {
  console.error("Unhandled promise rejection:", event.reason);
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LoginApp />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
